import { Utils } from "@/plugins/utils";
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        sections:[],
        loadingProducts: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async findHomeSections(store){
            store.state.loadingProducts = false
            await axios.get(process.env.VUE_APP_API_URI + "/Product/Home/FindHomeSections", { 'Content-Type': 'application/json' })
            .then(response => {
                store.state.sections = Utils.ValidateAxiosResponse(response.data);
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
            });
            store.state.loadingProducts = false
        }
    }
}