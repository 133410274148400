<template>
    <v-app id="inspire">
        <router-view name="AppBarMobile" v-if="$mq != 'lg'"></router-view>
        <router-view name="AppBarDesktop" v-else></router-view>
        <router-view name="AppBarResume"></router-view>
        <router-view name="AppWhatsAppButton"></router-view>
        <router-view name="LeftMenu"></router-view>
        <router-view name="AppMain"></router-view>
        <router-view name="AppSnackBar"></router-view>
        <router-view name="AppLoading"></router-view>
        <router-view name="Login"></router-view>
        <router-view name="Logoff"></router-view>
        <router-view name="SignUp"></router-view>
        <router-view name="Account"></router-view>
        <router-view name="Orders"></router-view>
        <router-view name="Order"></router-view>
        <router-view name="Contact"></router-view>
        <router-view name="About"></router-view>
        <router-view name="Help"></router-view>
        <router-view name="Products"></router-view>
        <router-view name="Cart"></router-view>
        <router-view name="Resume"></router-view>
        <router-view name="ConfirmationUser"></router-view>
        <router-view name="AppConfirmCustomers"></router-view>
        <router-view name="AppTextB2D"></router-view>
        <router-view name="AppManageProducts"></router-view>
        <router-view name="AppEditProduct"></router-view>
        <router-view name="RecoverPass"></router-view>
        <router-view name="Product"></router-view>
        <router-view name="AppFooter"></router-view>
        <router-view name="Dialog"></router-view>
    </v-app>
</template>

<script>

export default {
    data: () => ({}),
};
</script>