<template>
    <v-card
        color="#FDFDFD"
        height="48"
        width="100%"
        class="d-flex"
        tile
        flat
    >
        <v-text-field
            v-model="product.search"
            height="44"
            placeholder="Ex.: 123456/camisa branca..."
            dense
            flat
            hide-details
            solo
            outlined
            :color="parameters.primaryColor"
            class="align-self-center pb-3"
            append-icon="mdi-magnify"
            @keydown="keydown"
            @click:append="search"
        ></v-text-field>
    </v-card>
</template>

<script>
export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
    },
    methods:{
        keydown(event){
            if (event.keyCode === 13 && !event.shiftKey) {
                this.search()
                event.preventDefault();
            }
        },
        search(){
            if (this.product.search) {
                this.$store.dispatch("product/clearFilteredFields")
                this.$router.push({path: "/pesquisa", query:{ search: this.product.search }})
            }
        }
    }
};
</script>

<style>
</style>