import { Utils } from "@/plugins/utils";

export default {
    namespaced: true,
    state: {
        search: "",
        select: [],
        selectedOrder: {},
        myOrders: [],
    },
    getters: {
        filteredOrders(state){
            const filterOrders = order => {
                return (order.vd1pedido.includes(state.search) ||
                        order.orderDate.includes(state.search)) &&
                       (state.select.length == 0 ||
                        state.select.includes(order.vd1status))
            }
            return state.myOrders.filter(filterOrders)
        }
    },
    mutations: {
    },
    actions: {
        async showOrder(store, vd1pedido){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: `Buscando dados do pedido ${vd1pedido}...`, indeterminate: true }, {root: true})
            await this._vm.$axios.get(`/Order/Order/Find/${vd1pedido}`)
            .then(response => {
                let order = Utils.ValidateAxiosResponse(response.data);
                store.state.selectedOrder = order
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            return true
        },
        async findOrders(store){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Buscando seus pedidos...", indeterminate: true }, {root: true})
            await this._vm.$axios.get(`/Order/Order/FindOrders/${store.rootState.login.user.cf1cliente}`)
            .then(response => {
                let orders = Utils.ValidateAxiosResponse(response.data);
                store.state.myOrders = orders
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            return true
        },
        async buy(store){
            let order = store.rootGetters["cart/getSale"]
            order.Items = store.rootGetters["cart/getSalesLines"]
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Enviando pedido...", indeterminate: true }, {root: true})
            await this._vm.$axios.post("/Order/Order/Buy", order)
            .then(response => {
                let payloadBuy = Utils.ValidateAxiosResponse(response.data);
                store.dispatch("cart/updateSale", {
                    "vd1pedido": payloadBuy.Vd1pedido,
                }, { root: true })
                if (!payloadBuy.isError) {
                    store.rootState.cart.showOkOrder = true
                    store.dispatch("cart/clearCart", null, { root: true })
                    return true
                }
                throw new Error(payloadBuy.messageError) 
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
        },
    }
}