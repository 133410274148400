<template>
    <v-navigation-drawer v-model="parameters.openLeftMenu" fixed temporary style="min-width: 365px !important" :mobile-breakpoint="360">
        <v-list :color="parameters.secondaryColor" dark class="pb-0">
            <v-list-item v-if="login.userLogged">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2">
                        {{ login.user.cf1nome }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ login.user.cf1cliente | cpfCnpj}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link v-else @click="$router.push('/login')">
                <v-list-item-content>
                    <v-list-item-title>
                        Entre ou cadastre-se
                        <v-icon dark>mdi-login</v-icon>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider :style="{backgroundColor: parameters.secondaryColor + ' !important' }"></v-divider>

        <v-list dense nav flat>
            <template v-if="login.userLogged && login.administrator">
                <v-subheader class="subtitle-2">Gerenciamento</v-subheader>
                <v-list-item link @click="openNewCustomer">
                    <v-list-item-icon>
                        <v-icon :color="parameters.primaryColor">mdi-account-supervisor-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title :style="{color: parameters.primaryColor}">Novos clientes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="openProducts">
                    <v-list-item-icon>
                        <v-icon :color="parameters.primaryColor">mdi-tshirt-crew</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title :style="{color: parameters.primaryColor}">Produtos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="openB2D">
                    <v-list-item-icon>
                        <v-icon :color="parameters.primaryColor">mdi-domain</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title :style="{color: parameters.primaryColor}">Textos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-subheader class="subtitle-2">Área do cliente</v-subheader>
            </template>
            <v-list-item v-for="item in itemLeftMenu" :key="item.title" link @click="openMenu(item.route)" v-show="item.show">
                <v-list-item-icon>
                    <v-badge
                        v-if="item.icon == 'mdi-cart-outline'"
                        :content="itensOnCart"
                        :value="itensOnCart"
                        :color="parameters.secondaryColor"
                        overlap
                    >
                        <v-icon :color="parameters.primaryColor">
                            {{item.icon}}
                        </v-icon>
                    </v-badge>
                    <v-icon v-else :color="parameters.primaryColor">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title :style="{color: parameters.primaryColor}">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link v-if="login.userLogged" @click="logoff">
                <v-list-item-icon>
                    <v-icon :color="parameters.primaryColor">mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title :style="{color: parameters.primaryColor}">Sair</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider inset></v-divider>

        <v-list dense>
            <v-subheader class="subtitle-2">Coleções</v-subheader>
            
            <v-list-item v-for="(collection) in parameters.collections" :key="'COL_'+collection.co0colecao" link @click.stop="openCollection(collection.co0colecao)">
                <v-list-item-content>
                    <v-list-item-title
                        v-text="collection.co0nome"
                        class="text-left"
                        :style="{color: parameters.primaryColor}"
                    ></v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader class="subtitle-2">Categorias</v-subheader>
            
            <v-list-item v-for="(group) in parameters.groups" :key="'CAT_'+group.prsgrupo" link @click.stop="openGroup(group.prsgrupo)">
                <v-list-item-content>
                    <v-list-item-title
                        v-text="group.prsnome"
                        class="text-left"
                        :style="{color: parameters.primaryColor}"
                    ></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
        ...mapGetters({
            itensOnCart: 'cart/itensOnCart',
        }),
        userLogged(){
            let token = localStorage.getItem("token")
            let cf1cliente = localStorage.getItem("cf1cliente")
            let rule = localStorage.getItem("rule")
            return token && cf1cliente && rule
        },
        itemLeftMenu(){
            return [
                { title: "Minha Conta", icon: "mdi-account", route: "/minhaconta", show: true },
                { title: "Meu carrinho", icon: "mdi-cart-outline", route: "/carrinho", show: true },
                { title: "Meus Pedidos", icon: "mdi-package-variant-closed", route: "/meuspedidos", show: true },
                { title: "A empresa", icon: "mdi-information-outline", route: "/sobre", show: true },
                { title: "Lojas associadas", icon: "mdi-store", route: "/sobre", show: process.env.VUE_APP_ENV_NAME != "goldenarmy" },
                { title: "Ajuda", icon: "mdi-help-box", route: "/ajuda", show: true },
            ]
        }
    },
    methods:{
        logoff(){
            this.$router.push("/logoff")
        },
        openMenu(route){
            this.$router.push(route)
        },
        openGroup(prsgrupo){
            this.$store.dispatch("product/clearFilteredFields")
            this.$router.push({path: "/pesquisa", query:{ groups: [prsgrupo] }})
        },
        openCollection(co0colecao){
            this.$store.dispatch("product/clearFilteredFields")
            this.$router.push({path: "/pesquisa", query:{ collections: [co0colecao] }})
        },
        openNewCustomer(){
            this.$router.push({path: "/confirmacaoclientes"})
        },
        openProducts(){
            this.$router.push({path: "/manterprodutos"})
        },
        openB2D(){
            this.$router.push({path: "/textos"})
        }
    }
};
</script>

<style>
</style>