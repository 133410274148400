<template>
    <v-main>
        <v-container class="pb-12">
            <v-row v-if="confirmationUser.showMessage">
                <v-col cols="12">
                    <MessagesConfirmationUser />
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        :class="$mq != 'lg' ? 'mt-3': 'mt-6'"
                    >
                        Confirmação de conta
                    </h2>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <v-card outlined height="auto" max-width="400px" class="py-1 px-2" :style="{borderColor: parameters.secondaryColor}" elevation="0">
                        <v-card-text>
                            <v-subheader class="px-0 pb-3">Informe a senha utilizada no cadastro para confirmar a sua conta.</v-subheader>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-text-field
                                    v-model="cf1senha"
                                    :rules="passwordRules"
                                    label="Senha"
                                    type="password"
                                    required
                                    dense
                                    flat
                                    solo
                                    outlined
                                    prepend-icon="mdi-lock"
                                    :color="parameters.primaryColor"
                                    @keydown.enter.stop="confirm"
                                ></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn 
                                outlined
                                :color="parameters.secondaryColor"
                                @click="confirm"
                            >Confirmar cadastro</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const MessagesConfirmationUser = () =>
    import(
        /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "@/views/confirmationUser/MessagesConfirmationUser.vue"
    );

export default {
    data: () => ({
        cf1senha: "",
        passwordRules: [(v) => !!v || "A senha é obrigatória"],
        valid: false
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        confirmationUser: {
            get() {
                return this.$store.state.confirmationUser;
            },
        },
    },
    components:{
        MessagesConfirmationUser,
    },
    methods:{
        confirm(){
            let cf1cliente = this.$route.params.cf1cliente
            if (this.$refs.form.validate() && cf1cliente) {
                this.$store.dispatch("confirmationUser/confirmUser", { "cf1cliente": cf1cliente, "cf1senha": this.cf1senha })
            }
            this.cf1senha = ""
        }
    }
};
</script>

<style>
</style>