import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

const Home = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ /* webpackChunkName: "home" */ "@/views/Home.vue");
const Dialog = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "dialog" */ "@/views/Dialog.vue");
const AppMain = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ /* webpackChunkName: "home" */ "@/views/home/AppMain.vue");
const About = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "sobre" */ "@/views/About.vue");
const Login = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "login" */ "@/views/login/Login.vue");
const Logoff = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "login" */ "@/views/login/Logoff.vue");
const AppBarMobile = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/header/AppBarMobile.vue");
const AppBarDesktop = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/header/AppBarDesktop.vue");
const LeftMenu = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/LeftMenu.vue");
const AppWhatsAppButton = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/AppWhatsAppButton.vue");
const AppFooter = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/AppFooter.vue");
const AppSnackBar = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ "@/views/home/AppSnackBar.vue");
const AppLoading = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ "@/views/home/AppLoading.vue");
const SignUp = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "signUp" */ "@/views/signUp/SignUp.vue");
const Account = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "account" */ "@/views/account/Account.vue");
const Orders = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "orders" */ "@/views/order/Orders.vue");
const Order = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "order" */ "@/views/order/Order.vue");
const Contact = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "contact" */ "@/views/contact/Contact.vue");
const Products = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "products" */ "@/views/product/Products.vue");
const Product = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "product" */ "@/views/product/Product.vue");
const Help = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "Help" */ "@/views/help/Help.vue");
const Cart = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "Cart" */ "@/views/cart/Cart.vue");
const Resume = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "Resume" */ "@/views/resume/Resume.vue");
const AppBarResume = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "Resume" */ "@/views/resume/AppBarResume.vue");
const ConfirmationUser = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ /* webpackChunkName: "ConfirmationUser" */ "@/views/confirmationUser/ConfirmationUser.vue");
const RecoverPass = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ /* webpackChunkName: "RecoverPass" */ "@/views/recoverPass/RecoverPass.vue");
const AppConfirmCustomers = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "AppConfirmCustomers" */ "@/views/manager/customer/AppConfirmCustomers.vue");
const AppManageProducts = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "AppManageProducts" */ "@/views/manager/product/AppManageProducts.vue");
const AppEditProduct = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "AppEditProduct" */ "@/views/manager/product/AppEditProduct.vue");
const AppTextB2D = () => import(/* webpackMode: "lazy" */ /* webpackPrefetch: true */ /* webpackChunkName: "AppTextB2D" */ "@/views/manager/text/AppTextB2D.vue");

Vue.use(VueRouter)

const routes = [
	{
		path: '*',
		redirect: '/home'
	},
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		component: Home,
		children: [
			{
				path: '',
				name: 'Home',
				components: {
					AppBarMobile,
					Dialog,
					AppBarDesktop,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					AppMain,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/sobre',
		component: Home,
		children: [
			{
				path: '',
				name: 'about',
				components: {
					AppBarMobile,
					AppBarDesktop,
					LeftMenu,
					Dialog,
					AppWhatsAppButton,
					AppFooter,
					About,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/login',
		component: Home,
		children: [
			{
				path: '',
				name: 'Login',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					Login,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/cadastro',
		component: Home,
		children: [
			{
				path: '',
				name: 'SignUp',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					SignUp,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/minhaconta',
		meta: { requiresAuth: true },
		component: Home,
		beforeEnter: (to, from, next) => {
			store.dispatch("login/getUser")
			next()
		},
		children: [
			{
				path: '',
				name: 'account',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					Account,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/meuspedidos',
		meta: { requiresAuth: true },
		beforeEnter: (to, from, next) => {
			store.dispatch("order/findOrders")
			next()
		},
		component: Home,
		children: [
			{
				path: '',
				name: 'orders',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					Orders,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/pedido/:vd1pedido',
		meta: { requiresAuth: true },
		component: Home,
		children: [
			{
				path: '',
				name: 'order',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					Order,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/contato',
		component: Home,
		children: [
			{
				path: '',
				name: 'contact',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppFooter,
					Contact,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/pesquisa',
		component: Home,
		children: [
			{
				path: '',
				name: 'search',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppFooter,
					Products,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/produto/:pr0produto',
		component: Home,
		beforeEnter: (to, from, next) => {
			store.state.product.sheet = false
			next()
		},
		children: [
			{
				path: '',
				name: 'product',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppFooter,
					Product,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/ajuda',
		component: Home,
		children: [
			{
				path: '',
				name: 'help',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					Help,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/carrinho',
		meta: { requiresAuth: true },
		component: Home,
		children: [
			{
				path: '',
				name: 'cart',
				components: {
					AppBarMobile,
					AppBarDesktop,
					Dialog,
					LeftMenu,
					Cart,
					AppFooter,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/resumo',
		meta: { requiresAuth: true },
		component: Home,
		children: [
			{
				path: '',
				name: 'resume',
				components: {
					AppBarResume,
					Dialog,
					AppLoading,
					Resume,
				}		
			}
		]
	},
	{
		path: '/confirmacao/:cf1cliente',
		component: Home,
		children: [
			{
				path: '',
				name: 'confirmationUser',
				components: {
					AppBarResume,
					Dialog,
					AppLoading,
					ConfirmationUser,
					AppSnackBar,
				}		
			}
		]
	},
	{
		path: '/recuperacao',
		component: Home,
		children: [
			{
				path: '',
				name: 'recoverPass',
				components: {
					AppBarResume,
					Dialog,
					AppLoading,
					RecoverPass,
				}		
			}
		]
	},
	{
		path: '/confirmacaoclientes',
		component: Home,
		meta: { requiresAuth: true, administrator: true },
		beforeEnter: (to, from, next) => {
			store.dispatch("confirmCustomers/find")
			next()
		},
		children: [
			{
				path: '',
				name: 'confirmCustomers',
				components: {
					AppBarMobile,
					Dialog,
					AppBarDesktop,
					LeftMenu,
					AppConfirmCustomers,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/manterprodutos',
		component: Home,
		meta: { requiresAuth: true, administrator: true },
		beforeEnter: (to, from, next) => {
			store.dispatch("managmentProduct/findList", {})
			next()
		},
		children: [
			{
				path: '',
				name: 'managerProducts',
				components: {
					AppBarMobile,
					Dialog,
					AppBarDesktop,
					LeftMenu,
					AppManageProducts,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/produto/editar/:pr0produto',
		component: Home,
		meta: { requiresAuth: true, administrator: true },
		children: [
			{
				path: '',
				name: 'editProduct',
				components: {
					AppBarMobile,
					Dialog,
					AppBarDesktop,
					LeftMenu,
					AppEditProduct,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/textos',
		component: Home,
		meta: { requiresAuth: true, administrator: true },
		children: [
			{
				path: '',
				name: 'texts',
				components: {
					AppBarMobile,
					Dialog,
					AppBarDesktop,
					LeftMenu,
					AppTextB2D,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
	{
		path: '/logoff',
		component: Home,
		children: [
			{
				path: '',
				name: 'logoff',
				components: {
					AppBarMobile,
					Dialog,
					AppBarDesktop,
					LeftMenu,
					AppWhatsAppButton,
					AppFooter,
					AppMain,
					Logoff,
					AppSnackBar,
					AppLoading,
				}		
			}
		]
	},
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
};

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})


router.beforeEach(async (to, from, next) => {
	if (!(await store.dispatch("login/check")) && store.state.login.userLogged){
		store.state.login.userLogged = false
		next({ path: "/logoff" })
		return
	}

	if (to.matched.some(record => record.meta.requiresAuth) &&
		!store.state.login.userLogged)
	{
		let userLogged = false
		if(to.name == "editProduct" || to.name == "texts" || to.name == "managerProducts" ||  to.name == "confirmCustomers"){
			userLogged = await store.dispatch("login/verify")
		}
		if(!userLogged){
			next({ path: "/login" })
			return;
		}
	} 
	if (to.matched.some(record => record.meta.requiresAdmin) &&
		!store.state.login.administrator)
	{
		next({ path: "/login" })
		return;
	}
	next()
})

export default router
