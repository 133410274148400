<template>
    <v-app-bar app elevate-on-scroll color="#FDFDFD">
        <v-app-bar-nav-icon
            :style="{color: parameters.primaryColor}"
            @click="parameters.openLeftMenu = !parameters.openLeftMenu"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <a @click="$router.push('/')" :style="{color: parameters.secondaryColor}">
            <v-toolbar-title>{{ parameters.store.cf1fant }}</v-toolbar-title>
        </a>
        <v-spacer></v-spacer>
        <v-btn
            icon
            :color="parameters.primaryColor"
            @click.stop="parameters.openSearch = !parameters.openSearch"
        >
            <v-icon>{{ parameters.openSearch ? "mdi-magnify-close" : "mdi-magnify"}}</v-icon>
        </v-btn>
        <v-menu offset-y :nudge-width="130">
            <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    icon
                    :color="parameters.primaryColor"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </template>
            <AppBarCardMyAccount />
        </v-menu>
        
        <AppCartBtn />
        
        <template v-slot:extension v-if="parameters.openSearch">
            <v-expand-transition>
                <CardSearch v-if="parameters.openSearch" />
            </v-expand-transition>
        </template>
    </v-app-bar>
</template>

<script>
const CardSearch = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/CardSearch.vue"
    );
const AppBarCardMyAccount = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/header/AppBarCardMyAccount.vue"
    );
const AppCartBtn = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/header/AppCartBtn.vue"
    );

export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        cart: {
            get() {
                return this.$store.state.cart;
            },
        },
    },
    components: {
        CardSearch,
        AppBarCardMyAccount,
        AppCartBtn
    },
};
</script>

<style>
</style>