import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
		themes: {
			light: {
				primary: process.env.VUE_APP_PRIMARY_COLOR, // #E53935
				secondary: process.env.VUE_APP_SECONDARY_COLOR, // #FFCDD2
				accent: colors.pink.base, // #3F51B5
			},
		},
  },
}

export default new Vuetify(opts)
