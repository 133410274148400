<template>
    <v-row class="pa-0 ma-0">
        <v-col cols="4" class="white--text">
            <AppFooterAboutUs />
        </v-col>
        <v-divider
            vertical
            class="my-10"
            :style="{ backgroundColor: 'white !important' }"
        ></v-divider>
        <v-col cols="4">
            <AppFooterInstitutional />
        </v-col>
        <v-divider
            vertical
            class="my-10"
            :style="{ backgroundColor: 'white !important' }"
        ></v-divider>
        <v-col cols="4">
            <v-list-item-title
                class="font-weight-bold white--text subtitle-2 pb-5"
                >ATENDIMENTO</v-list-item-title
            >
            <v-list dense disabled class="transparent justify-center d-flex">
                <AppFooterContactList />
            </v-list>
        </v-col>
    </v-row>
</template>

<script>
const AppFooterContactList = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterContactList.vue"
    );
const AppFooterAboutUs = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterAboutUs.vue"
    );
const AppFooterInstitutional = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterInstitutional.vue"
    );

export default {
    components:{
        AppFooterContactList,
        AppFooterAboutUs,
        AppFooterInstitutional
    }
};
</script>

<style>
</style>