import Vue from 'vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'
import VueMq from 'vue-mq'
import {
  VueMaskDirective
} from 'v-mask'
import './plugins/filter';
require('./css/index.css')

//tiptap
import {
  // layout
  Container,
  Header,
  Main,
  Footer,
  Button,
} from 'element-ui';

import {
  ElementTiptapPlugin
} from 'element-tiptap';
// import ElementUI's styles
import 'element-ui/lib/theme-chalk/index.css';
// import this package's styles
import 'element-tiptap/lib/index.css';

import Axios from './plugins/axios'
Vue.prototype.$axios = Axios();

Vue.config.productionTip = false

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 480,
    md: 768,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_KEY_MAPS,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    region: 'PT',
    language: 'pt'
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.directive('mask', VueMaskDirective);

Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Button);
// use this package's plugin
Vue.use(ElementTiptapPlugin, {
  /* plugin options */ });

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')