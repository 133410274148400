<template>
    <v-btn
        v-if="!parameters.openLeftMenu && parameters.store.b2bverwhat"
        color="#4AC959"
        dark
        fixed
        bottom
        right
        fab
        elevation="6"
        style="z-index: 5 !important; bottom: 20% !important;"
        @click="openWhatsApp"
    >
        <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods:{
        openWhatsApp(){
            window.open(`https://wa.me/${this.parameters.store.b2bwhats}?text=Ol%C3%A1%21%21+Gostaria+de+tirar+umas+d%C3%BAvidas...`)
        }
    }
};
</script>

<style>
</style>