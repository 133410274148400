<template>
    <v-carousel
        :show-arrows="false"
        :height="$mq != 'lg' ? '546px' : ''"
        :interval="4000"
        hide-delimiter-background
        cycle
        class="pt-4"
        :class="$mq != 'lg' ? 'd-flex justify-center ' : ''"
    >
        <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            :class="$mq != 'lg' ? '' : 'vCarouselItem'"
            :width="$mq != 'lg' ? '336px' : ''"
        ></v-carousel-item>
    </v-carousel>
</template>

<script>
export default {
    data: () => ({
    }),
    computed: {
        items(){
            let lstBanners = []
            for (let index = 0; index < process.env.VUE_APP_BANNERS; index++) {
                lstBanners.push({
                    src: `../../../../img/${process.env.VUE_APP_ENV_NAME}/banner${index+1}${this.$mq == 'lg' ? '' : 'mobile'}.jpg`,
                })
            }
            return lstBanners
        }
    }
};
</script>

<style scoped>
    .vCarouselItem >>> .v-carousel__item{
        aspect-ratio: 20/5 !important;
    }
</style>