<template>
    <v-slide-group class="slide-group" show-arrows>
        <v-slide-item
            v-for="(product, m) in products"
            :key="`card-${m}`"
        >
            <div>
                <ProductCard
                    :product="product"
                    classCard="productCardMain"
                    outlined
                    flat
                />
            </div>
        </v-slide-item>
    </v-slide-group>
</template>

<script>
const ProductCard = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductCard.vue"
    );

export default {
    props:{
        products:{
            type: Array,
            required: true
        }
    },
    components:{
        ProductCard
    }
};
</script>

<style scoped>
    .slide-group >>> .v-slide-group__next, .slide-group >>> .v-slide-group__prev{
        min-width: 34px !important;
        flex: 0 1 22px !important;
    }

    .slide-group >>> .v-slide-group__content{
        padding: 2px 0px !important;
    }
    .slide-group >>> .v-slide-group__wrapper{
        touch-action: pan-y !important;
    }
</style>