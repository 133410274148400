export const Utils = {
	/* used in callback of axios */
	ValidateAxiosResponse: (jsonResponse) => {
		if (jsonResponse !== null && typeof jsonResponse === 'object') {
			if (jsonResponse.isError) {
				throw new Error(jsonResponse.messageError);
			}
			return jsonResponse.objectModel;
		}

		return new Error('Retorno não é um JSON válido.');
	}
};