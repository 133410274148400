import { Utils } from "@/plugins/utils";
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        openLeftMenu: false,
        openSearch: false,
        collections: [],
        groups: [],
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        secondaryColor: process.env.VUE_APP_SECONDARY_COLOR,
        store: {},
        footerMenus:[
            { title: "Home", route: "/", show: true},
            /* { title: "Empresa", route: "/empresa"}, , show: true*/
            { title: "A empresa", route: "/sobre", show: true},
            { title: "Lojas associadas", route: "/sobre", show: process.env.VUE_APP_ENV_NAME != "goldenarmy"},
            { title: "Contato", route: "/contato", show: true},
        ],
        snackbarModel:{
            show: false,
            timeout: 1000,
            text: ""
        },
        loadingGlobal:{
            showLoadingGlobal: false,
            text: "",
            indeterminate: false,
            size: 64,
            rotate: null,
            value: null
        },
        states: [],
        loadingRelatedStores: false,
        loadingCompanyInfos: false,
        dialog: {
            show: false,
            titleText: "",
            titleColor: "",
            text: "",
            confirmBtn: false,
            confirmText: "",
            confirmAction: null,
            cancelText: "Ok",
            cancelAction: null
        }
    },
    getters: {
    },
    mutations: {
        showSnackBar(state, model){
            state.snackbarModel = model
        },
        openGlobalLoading(state, {showLoadingGlobal = false, text = "", indeterminate = false, size = 64, rotate = null, value = null}){
            state.loadingGlobal = {
                showLoadingGlobal,
                text,
                indeterminate,
                size,
                rotate,
                value
            }
        },
        resetDialog(state){
            state.dialog.show= false,
            state.dialog.titleText= "",
            state.dialog.titleColor= "",
            state.dialog.text= "",
            state.dialog.confirmBtn= false,
            state.dialog.confirmText= "",
            state.dialog.confirmAction= null,
            state.dialog.cancelText= "Ok",
            state.dialog.cancelAction= null
        },
        openDialog(state, { titleText= "", titleColor= "", text= "", confirmBtn= false, confirmText= "", confirmAction= null, cancelText= "Ok", cancelAction= null }){
            state.dialog.show = true
            state.dialog.titleText = titleText
            state.dialog.titleColor = titleColor
            state.dialog.text = text
            state.dialog.confirmBtn = confirmBtn
            state.dialog.confirmText = confirmText
            state.dialog.confirmAction = confirmAction
            state.dialog.cancelText = cancelText
            state.dialog.cancelAction = cancelAction
        }
    },
    actions: {
        showSnackBar(store, model){
            store.commit("showSnackBar", model)
        },
        openGlobalLoading(store, model){
            store.commit("openGlobalLoading", model)
        },
        async findCompanyInfos(store){
            store.state.loadingCompanyInfos = true
            await axios.get(process.env.VUE_APP_API_URI + "/Company/Company/FindCompanyInfos", { 'Content-Type': 'application/json' })
            .then(response => {
                store.state.store = Utils.ValidateAxiosResponse(response.data);
            })
            .catch((error) => {
                store.dispatch("openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                })
            });
            store.state.loadingCompanyInfos = false
        },
        async findMenus(store){
            await axios.get(process.env.VUE_APP_API_URI + "/Company/Company/FindMenus", { 'Content-Type': 'application/json' })
            .then(response => {
                let menuModel = Utils.ValidateAxiosResponse(response.data);
                store.state.collections = menuModel.collections
                store.state.groups = menuModel.groups
            })
            .catch((error) => {
                store.dispatch("openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                })
            });
        },
        async findRelatedStores(store){
            store.state.loadingRelatedStores = true
            await axios.get(process.env.VUE_APP_API_URI + "/RelatedStores/RelatedStores/Find", { 'Content-Type': 'application/json' })
            .then(response => {
                response = Utils.ValidateAxiosResponse(response.data);
                store.state.states = response
            })
            .catch((error) => {
                store.dispatch("openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                })
            });
            store.state.loadingRelatedStores = false
        },
        resetDialog(store){
            store.commit("resetDialog")
        },
        openDialog(store, params){
            store.commit("openDialog", params)
        },
        async saveb2d(store){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Gravando textos do E-Commerce", indeterminate: true }, {root: true})
            await this._vm.$axios.put("/Company/Company/SaveB2D" , 
            {
                b2dsobre: store.state.store.b2dsobre,
                b2ddescri: store.state.store.b2ddescri
            })
            .then(response => {
                let success = Utils.ValidateAxiosResponse(response.data);
                if (success) {
                    store.dispatch("findCompanyInfos")
                    return true
                }
                throw new Error("Não foi possível atualizar os textos do E-Commerce")
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
        }
    }
}