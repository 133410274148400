import { Utils } from "@/plugins/utils";
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        showMessage: false,
        header: "",
        subheader: "",
        btnTxt: false,
        isError: false
    },
    getters: {
      
    },
    mutations: {
        setSucess(state){
            state.header = "Cadastro confirmado!"
            state.subheader = "Aguarde a aprovação do seu cadastro por e-mail. Com seu cadastro aprovado, você vai poder acessar o nosso portal, para aproveitar as ofertas e conhecer nossos produtos."
            state.btnTxt = "Acessar"
            state.isError = false
            state.showMessage= true
        },
        setError(state){
            state.header = "Infelizmente não foi possível confirmar o seu cadastro"
            state.subheader = "Usuário não localizado ou credenciais de acesso inválidos. verifique e tente novamente."
            state.btnTxt = "Tentar novamente"
            state.isError = true
            state.showMessage= true
        },
        clear(state){
            state.showMessage= false
            state.header= ""
            state.subheader=""
            state.btnTxt= false
            state.isError= false
        }
    },
    actions: {
        async confirmUser(store, userModel){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Confirmando seu cadastro...", indeterminate: true }, {root: true})
            await axios.post(process.env.VUE_APP_API_URI + "/User/User/ConfirmUser", userModel , { 'Content-Type': 'application/json' })
            .then(response => {
                let isConfirmed = Utils.ValidateAxiosResponse(response.data);
                if (isConfirmed) {
                    store.commit("setSucess")
                    return
                }
                store.commit("setError")
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
        },
        clear(store){
            store.commit("clear")
        }
    }
}