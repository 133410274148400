<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    mounted(){
        this.$store.dispatch("login/verify")
        this.$store.dispatch("parameters/findCompanyInfos")
        this.$store.dispatch("parameters/findMenus")
        this.$store.dispatch("home/findHomeSections")
    }
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
#app >>> .v-main {
    min-height: 80% !important;
}
</style>
