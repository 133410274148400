import { Utils } from "@/plugins/utils";
import router from '@/router';

export default {
    namespaced: true,
    state: {
        lstProducts: [],
        loading: false,
        product: {},
        limit: 20,
        page: 1,
        hasNext: false,
        search: ""
    },
    getters: {
      
    },
    mutations: {
        setParams(state, managmentProductsModel){
            state.lstProducts = managmentProductsModel.products
            state.limit = managmentProductsModel.limit
            state.page = managmentProductsModel.page
            state.hasNext = managmentProductsModel.hasNext
        },
        clear(state){
            state.product = {}
            state.lstProducts = []
            state.loading = false
        }
    },
    actions: {
        async findList(store, {search = "", limit = 20, page = 1}){
            store.state.loading = true
            await this._vm.$axios.get(`/Product/Manage/FindManagmentProducts?search=${search}&limit=${limit}&page=${page}`)
            .then(response => {
                let managmentProductsModel = Utils.ValidateAxiosResponse(response.data);
                store.commit("setParams", managmentProductsModel)
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.state.search = ""
            store.state.loading = false
            return true
        },
        async find(store, pr0produto){
            store.state.loading = true
            await this._vm.$axios.get("/Product/Manage/FindManagmentProduct/" + pr0produto)
            .then(response => {
                let pr0 = Utils.ValidateAxiosResponse(response.data);
                store.state.product = pr0
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.state.loading = false
        },
        async update(store, lstB2i){
            let index = 1
            for (const b2i of lstB2i) {
                store.dispatch("parameters/openGlobalLoading", {
                    showLoadingGlobal: true,
                    text: `Salvando imagens do produto: ${index} de ${lstB2i.length}<br/>Aguarde tomando um cafézinho☕`,
                    size: 100,
                    rotate: -90,
                    value: (index/lstB2i.length)*100
                }, {root: true})

                var formData = new FormData();
                formData.append("image", b2i);

                let success = await this._vm.$axios.post("/Product/Image/Insert/"+store.state.product.pr0produto, formData, { headers: {'Content-Type': 'multipart/form-data' }})
                .then(response => {
                    let success = Utils.ValidateAxiosResponse(response.data);
                    return success
                })
                .catch((error) => {
                    if (!(error.response && error.response.status === 401)) {
                        store.dispatch("parameters/openDialog", {
                            titleText: "Erro",
                            titleColor: "red",
                            text: error.message
                        }, { root: true })
                    }
                    return false
                });
                store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
                if (!success) {
                    return success
                }
                index++
            }

            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: `Atualizando produto ${store.state.product.pr0desc}...`, indeterminate: true }, {root: true})
            await this._vm.$axios.put("/Product/Manage/Update" , store.state.product)
            .then(response => {
                let success = Utils.ValidateAxiosResponse(response.data);
                if (success) {
                    store.dispatch("find", store.state.product.pr0produto)
                    return true
                }
                throw new Error(`Não foi possível atualizar o produto ${store.state.product.pr0desc}.`)
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            return true
        },
        async setMain(store, b2i){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: `Alterando imagem principal...`, indeterminate: true }, {root: true})
            await this._vm.$axios.put("/Product/Image/SetMain" , b2i)
            .then(response => {
                let success = Utils.ValidateAxiosResponse(response.data);
                if (!success) {
                    throw new Error("Não foi possível marcar a imagem como principal")
                }
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            store.dispatch("find", store.state.product.pr0produto)
        },
        async deleteImage(store, b2icodigo){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Excluindo imagem do produto...", indeterminate: true }, {root: true})
            await this._vm.$axios.delete(`/Product/Image/Delete/${b2icodigo}`)
            .then(response => {
                let success = Utils.ValidateAxiosResponse(response.data);
                if (!success) {
                    throw new Error("Não foi possível excluir a imagem do produto")
                }
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            store.dispatch("find", store.state.product.pr0produto)
        },
        async setOptionImage(store, {pr2opcao, image}){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Salvando alterações...", indeterminate: true }, {root: true})

            var formData = new FormData();
            formData.append("image", image);

            let success = await this._vm.$axios.post("/Product/Image/InsertImageOption/"+store.state.product.pr0produto+"/"+pr2opcao, formData, { headers: {'Content-Type': 'multipart/form-data' }})
            .then(response => {
                let success = Utils.ValidateAxiosResponse(response.data);
                return success
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            store.dispatch("find", store.state.product.pr0produto)
            return success
        },
        async deleteImageOption(store, pr2opcao){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Salvando alterações...", indeterminate: true }, {root: true})

            let success = await this._vm.$axios.delete("/Product/Image/DeleteImageOption/"+store.state.product.pr0produto+"/"+pr2opcao)
            .then(response => {
                let success = Utils.ValidateAxiosResponse(response.data);
                return success
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            store.dispatch("find", store.state.product.pr0produto)
            return success
        }
    }
}