<template>
    <v-container>
        <v-row class="pt-5">
            <template v-for="section in home.sections">
                <v-col
                    :key="section.id"
                    class="mt-2"
                    cols="12"
                    :style="{ color: '#424242' }"
                >
                    <v-subheader class="justify-center font-weight-bold text-h5">{{ section.name }}</v-subheader>
                </v-col>

                <v-col cols="12" class="pa-0" :key="`slide-group-${section.id}`">
                    <SlideProducts :products="section.products"/>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
const SlideProducts = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/template/SlideProducts.vue"
    );

export default {
    data: () => ({        
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        home: {
            get() {
                return this.$store.state.home;
            },
        },
    },
    components:{
        SlideProducts
    }
};
</script>

<style>
</style>