import Vue from 'vue'
import Vuex from 'vuex'

import parameters from "./modules/parameters"
import login from "./modules/login"
import cart from "./modules/cart"
import signUp from "./modules/signUp"
import order from "./modules/order"
import contact from "./modules/contact"
import product from "./modules/product"
import home from "./modules/home"
import confirmationUser from "./modules/confirmationUser"
import recoverPass from "./modules/recoverPass"
import confirmCustomers from "./modules/confirmCustomers"
import managmentProduct from "./modules/managmentProduct"

Vue.use(Vuex)

export default new Vuex.Store({
	/* Read.. https://vuex.vuejs.org/guide/modules.html */
	modules: { parameters, login, cart, signUp, order, contact, product, home, confirmationUser, recoverPass, confirmCustomers, managmentProduct },
})