import axios from 'axios'
import router from '@/router';
import store from '@/store/index';

export default () => {
	const instance = axios.create({
		baseURL: process.env.VUE_APP_API_URI,
		withCredentials: false,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	})
	const CancelToken = axios.CancelToken;

	instance.interceptors.response.use((response) => {
		return response
	}, (error) => {
		if (401 === error.response.status) {
			router.push("/logoff")
		}
		return Promise.reject(error)
	})

	instance.interceptors.request.use(async config  => {
		const source = CancelToken.source()
		config.CancelToken = source.token
		if (!(await store.dispatch("login/check")) && store.state.login.userLogged){
			source.cancel("sessão expirou")
			router.push("/logoff")
		}
		return config
	}, error => {
		return Promise.reject(error)
	})

	return instance
}
