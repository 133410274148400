import { Utils } from "@/plugins/utils";
import axios from 'axios'
import router from '@/router';

export default {
    namespaced: true,
    state: {
        account: {
            cf1cliente: "",
            cf1senha: "",
            confirmPassword: "",
            cf1nome: "",
            cf1fant: "",
            cf1insest: "",
            cf1confone: "",
            cf1fone: "",
            cf1email: "",
            cf1ender1: "",
            cf1cep: "",
            cf1bairro: "",
            cf1numero: "",
            cf1compl: "",
            cf3nome: "",
            cf3estado: "",
            shippingAddress: null
        },
        isError: false,
        messageError: ""
    },
    getters: {
        cf1Model(state){
            let cf1 = { ...state.account }
            if (state.account.shippingAddress) {
                cf1.shippingAddress = {
                    cfbcliente: state.account.cf1cliente,
                    cfbendent: `${state.account.shippingAddress.cf1ender1}, ${state.account.shippingAddress.cf1numero}`,
                    cfbproximo: state.account.shippingAddress.cf1compl,
                    cfbcepent: state.account.shippingAddress.cf1cep
                }
            }   
            return cf1
        }
    },
    mutations: {
        setError(state, message){
            state.isError = true
            state.messageError = message
        },
        clearFields(state){
            state.account = {
                cf1cliente: "",
                cf1senha: "",
                confirmPassword: "",
                cf1nome: "",
                cf1fant: "",
                cf1insest: "",
                cf1confone: "",
                cf1fone: "",
                cf1email: "",
                cf1ender1: "",
                cf1cep: "",
                cf1bairro: "",
                cf1numero: "",
                cf1compl: "",
                cf3nome: "",
                cf3estado: "",
                shippingAddress: null
            }
            state.isError = false,
            state.messageError = ""
        }
    },
    actions: {
        async signUp(store){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Enviando dados...", indeterminate: true }, {root: true})
            await axios.post(process.env.VUE_APP_API_URI + "/User/User/Insert", store.getters.cf1Model , { 'Content-Type': 'application/json' })
            .then(response => {
                let isSave = Utils.ValidateAxiosResponse(response.data);
                if (isSave) {
                    store.commit("clearFields")
                    store.dispatch("parameters/openDialog", {
                        titleText: "Recebemos seu cadastro",
                        titleColor: "green",
                        text: "Enviamos a confirmação do cadastro para seu e-mail."
                    }, { root: true })
                    router.push("/")
                    return true
                }
                throw new Error("Não foi possível realizar o cadastro.")
            })
            .catch((error) => {
                store.commit("setError", error.message)
                store.dispatch(
                    "parameters/showSnackBar",
                    {
                        text: error.message,
                        show: true,
                        timeout: 4000
                    },
                    {root: true})
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            return true
        },
        async getCep(store, postalCode) {
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Buscando CEP...", indeterminate: true }, {root: true})
            return await axios.get(`${process.env.VUE_APP_API_CEP}/${postalCode}/json`, { 'Content-Type': 'application/json' })
            .then(response => {
                store.rootState.parameters.loading = false
                let addressModel = response.data;
                store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
                return addressModel
            })
            .catch( error => {
                store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
                store.commit("setError", error.message)
                return {}
            })
        }
    }
}