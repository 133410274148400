<template>
    <div class="pt-2">
        <img :src="logo" :alt="parameters.store.cf1fant" :height="height" width="auto"/>
        <p class="body-2">
            {{ parameters.store.b2ddescri }}
        </p>
    </div>
</template>

<script>
export default {
    props:{
        height:{
            default: "60px",
            type: String
        }
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        logo(){
            if (process.env.VUE_APP_ENV_NAME == "imperial")
                return `../../../../../img/${process.env.VUE_APP_ENV_NAME}/logo3.png`
            return `../../../../../img/${process.env.VUE_APP_ENV_NAME}/logo.png`
        }
    },
};
</script>

<style>
</style>