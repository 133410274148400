import { Utils } from "@/plugins/utils";
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        contactModel:{
            name: "",
            email: "",
            cellphone: "",
            phone: "",
            message: "",
        }
    },
    getters: {
      
    },
    mutations: {
    },
    actions: {
        async sendMessage(store){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Enviando sua mensagem...", indeterminate: true }, {root: true})
            await axios.post(process.env.VUE_APP_API_URI + "/Contact/Contact/SendMessage", store.state.contactModel ,{ 'Content-Type': 'application/json' })
            .then(response => {
                let payload = Utils.ValidateAxiosResponse(response.data);
                if (payload){
                    store.dispatch("parameters/showSnackBar", {
                        text: "Sua mensagem foi enviada. Agradecemos o contato!",
                        show: true,
                        timeout: 5000
                    }, {root: true})
                    store.state.contactModel= {
                        name: "",
                        email: "",
                        cellphone: "",
                        phone: "",
                        message: "",
                    }
                    return true
                }
                throw new Error("Não foi possível enviar sua mensagem.");
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
        }
    }
}