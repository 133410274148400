<template>
    <v-expansion-panels accordion flat>
        <v-expansion-panel>
            <v-expansion-panel-header :color="parameters.primaryColor">SOBRE NÓS</v-expansion-panel-header>
            <v-expansion-panel-content :color="parameters.primaryColor">
                <AppFooterAboutUs />
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header :color="parameters.primaryColor">INSTITUCIONAL</v-expansion-panel-header>
            <v-expansion-panel-content :color="parameters.primaryColor">
                <AppFooterInstitutional />
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header :color="parameters.primaryColor">ATENDIMENTO</v-expansion-panel-header>
            <v-expansion-panel-content :color="parameters.primaryColor">
                <v-list dense disabled class="transparent justify-center d-flex">
                    <AppFooterContactList />
                </v-list>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
const AppFooterContactList = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterContactList.vue"
    );
const AppFooterAboutUs = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterAboutUs.vue"
    );
const AppFooterInstitutional = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterInstitutional.vue"
    );
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods: {
        openRoute(route){
            this.$router.push(route)
        }
    },
    components:{
        AppFooterContactList,
        AppFooterAboutUs,
        AppFooterInstitutional
    }
};
</script>

<style>
</style>