<template>
    <div
        class="d-flex justify-center align-center pa-0"
        :style="{ backgroundColor: parameters.secondaryColor }"
        style="width: 100% !important; height: 48px !important"
    >
        <v-menu  bottom offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    elevation="0"
                    color="white"
                    class="text-capitalize"
                    height="48px"
                    v-text="'Coleções'"
                >
                    <!-- :color="parameters.primaryColor" -->
                </v-btn>
            </template>
            <v-list color="#FDFDFD" min-width="300px">
                <v-list-item 
                    v-for="(collection) in parameters.collections" 
                    :key="collection.co0colecao"                    
                    @click="openCollection(collection.co0colecao)"
                >
                    <v-list-item-title :style="{color: parameters.primaryColor}">{{ collection.co0nome | capitalize}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <template v-for="group in groupsToShow">
            <v-btn
                text
                elevation="0"
                color="white"
                height="48px"
                :key="group.prsgrupo"
                @click="openGroup(group.prsgrupo)"
                class="text-capitalize"
            >
                <!-- :color="parameters.primaryColor" -->
                {{ group.prsnome | capitalize }}
            </v-btn>
        </template>
        <v-menu  bottom offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    elevation="0"
                    color="white"
                    class="text-capitalize"
                    height="48px"
                    v-text="'Todas as categorias'"
                >
                    <!-- :color="parameters.primaryColor" -->
                </v-btn>
            </template>
            <v-list color="#FDFDFD" min-width="300px">
                <v-list-item 
                    v-for="(group) in parameters.groups" 
                    :key="group.prsgrupo"
                    @click="openGroup(group.prsgrupo)"
                >
                    <v-list-item-title :style="{color: parameters.primaryColor}">{{ group.prsnome | capitalize}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        groupsToShow(){
            return this.parameters.groups.slice(0,4)
        }
    },
    methods:{
        openGroup(prsgrupo){
            window.open(`/pesquisa?groups=${prsgrupo}`, "_self")
        },
        openCollection(co0colecao){
            window.open(`/pesquisa?collections=${co0colecao}`, "_self")
        }
    }
};
</script>

<style>
</style>