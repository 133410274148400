import { Utils } from "@/plugins/utils";
import axios from 'axios'
import router from '@/router';

export default {
    namespaced: true,
    state: {
        userLogged: false,
        user: {},
        showForgotPass: false,
        loadingForgotPass: false,
        isError: false,
        messageError: "",
        loadingLogin: false,
        showChangePass: false,
        administrator: false,
        loadingCustomer: false
    },
    getters: {
        customerAddress(state){
            return {
                "cf1cep": state.user.cf1cep,
                "cf1ender1": state.user.cf1ender1,
                "cf1bairro": state.user.cf1bairro,
                "cf1numero": state.user.cf1numero,
                "cf3nome": state.user.cf3nome,
                "cf3estado": state.user.cf3estado,
                "cf1compl ": state.user.cf1compl
            }
        }
    },
    mutations: {
        auth(state, user){
            state.user = user
            state.userLogged = true
            state.loadingLogin = false
            state.isError = false
            state.administrator = user.rule === "Administrator"
        },
        logoff(state){
            state.userLogged = false
            state.user = {}
            state.administrator = false
            localStorage.clear()
        }
    },
    actions: {
        async auth(store){
            store.state.loadingLogin = true
            store.state.isError = false
            let authModel = {
                cf1login : store.state.user.cf1cliente,
                cf1senha : store.state.user.cf1senha
            }
            await axios.post(process.env.VUE_APP_API_URI + "/Authentication/Authentication/Auth", authModel, { 'Content-Type': 'application/json' })
            .then(response => {
                let authenticatedModel = Utils.ValidateAxiosResponse(response.data);
                if (authenticatedModel.authenticated) {
                    localStorage.setItem("token", authenticatedModel.token)
                    localStorage.setItem("cf1cliente", authenticatedModel.cf1.cf1cliente)
                    localStorage.setItem("rule", authenticatedModel.cf1.rule)
                    this._vm.$axios.defaults.headers.common['Authorization'] = 'Bearer '.concat(authenticatedModel.token);
                    store.commit("auth", authenticatedModel.cf1)
                    store.dispatch("cart/find", null, {root: true})
                    router.push("/")
                    return true
                }
                throw new Error("Não foi possível realizar o login")
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
            });
            store.state.loadingLogin = false
            return true
        },
        async SendRecoverPass(store, cf1cliente){
            let payload = await axios.put(process.env.VUE_APP_API_URI + "/Authentication/Authentication/SendRecoverPass", { cf1cliente }, { 'Content-Type': 'application/json' })
            .then(response => {
                let isSend = Utils.ValidateAxiosResponse(response.data);
                if (isSend) {
                    store.dispatch("parameters/showSnackBar", {
                        text: "E-mail enviado com sucesso!",
                        show: true,
                        timeout: 4000
                    }, {root: true})
                    store.state.showForgotPass = false
                    return true
                }
                throw new Error("Não foi possível enviar o e-mail de recuperação de senha.")
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
                return false
            });
            store.state.loadingForgotPass = false
            return payload
        },
        async logoff(store){
            await this._vm.$axios.delete("/Authentication/Authentication/Logoff")
            store.rootState.parameters.openLeftMenu = false
            store.dispatch("cart/clearCart", null, {root: true})
            store.commit("logoff")
            setTimeout(() => {
                store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
                router.push("/")
            }, 3000);
        },
        async updateCredentials(store, changePassModel){
            changePassModel.cf1cliente = store.state.user.cf1cliente
            store.state.showChangePass = false
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Alterando a senha...", indeterminate: true }, {root: true})
            let payload = await this._vm.$axios.put("/Authentication/Authentication/UpdateCredentials", changePassModel)
            .then(response => {
                let isUpdate = Utils.ValidateAxiosResponse(response.data);
                if (isUpdate) {
                    store.dispatch(
                        "parameters/showSnackBar",
                        {
                            text: "Senha alterada com sucesso!",
                            show: true,
                            timeout: 4000
                        },
                        {root: true})
                    return true
                }
                throw new Error("Não foi possível atualizar alterar a sua senha.")
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            store.state.showChangePass = false
            return payload
        },
        async update(store, userModel){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Alterando seus dados...", indeterminate: true }, {root: true})
            await this._vm.$axios.put("/User/User/Update", userModel)
            .then(response => {
                let isUpdate = Utils.ValidateAxiosResponse(response.data);
                if (isUpdate) {
                    store.dispatch(
                        "parameters/showSnackBar",
                        {
                            text: "Dados alterados com sucesso!",
                            show: true,
                            timeout: 4000
                        },
                        {root: true})
                    store.dispatch("getUser")
                    return true
                }
                throw new Error("Não foi possível atualizar seus dados.")
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
        },
        check(store){
            let token = localStorage.getItem("token")
            let cf1cliente = localStorage.getItem("cf1cliente")
            let rule = localStorage.getItem("rule")
            return token && cf1cliente && rule
        },
        verify(store){
            let token = localStorage.getItem("token")
            let cf1cliente = localStorage.getItem("cf1cliente")
            let rule = localStorage.getItem("rule")
            if (token && cf1cliente && rule) {
                store.state.user.cf1cliente = cf1cliente
                store.state.administrator = rule === "Administrator"
                this._vm.$axios.defaults.headers.common['Authorization'] = 'Bearer '.concat(token);
                store.state.userLogged = true
                store.dispatch("getUser")
                store.dispatch("cart/find", null, {root: true})
                return true;
            }
            localStorage.clear()
            return false;
        },
        async getUser(store){
            store.state.loadingCustomer = true
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Buscando seus dados...", indeterminate: true }, {root: true})
            let payload = await this._vm.$axios.get("/User/User/Get/"+store.state.user.cf1cliente, { 'Content-Type': 'application/json' })
            .then(response => {
                let cf1 = Utils.ValidateAxiosResponse(response.data);
                store.state.user = cf1
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
            store.state.loadingCustomer = false
            return payload
        }
    }
}