<template>
    <div style="max-width: min-content !important">
        <v-list-item v-for="(contact, j) in parameters.store.contacts" :key="j">
            <v-list-item-icon class="pr-1">
                <v-icon v-text="contact.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="text-left" v-text="contact.title"></v-list-item-title>
                <v-list-item-subtitle class="text-left">
                    <template v-if="contact.title == 'WhatsApp'">{{ contact.contact | phone }}</template>
                    <template v-else>{{ contact.contact }}</template>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
};
</script>

<style>
</style>