<template>
    <v-badge
        :content="itensOnCart"
        :value="itensOnCart"
        :color="parameters.secondaryColor"
        overlap
        offset-x="25"
        offset-y="25"
        @click="openCart"
    >
        <v-btn icon @click="openCart">
            <v-icon :color="parameters.primaryColor">mdi-cart-outline</v-icon>
        </v-btn>
    </v-badge>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed:{
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        ...mapGetters({
            itensOnCart: 'cart/itensOnCart',
        })
    },
    methods:{
        openCart(){
            this.$router.push("/carrinho")
        }
    }
};
</script>

<style>
</style>