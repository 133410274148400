<template>
    <v-app-bar app height="81px" class="v-app-bar" color="#FDFDFD" elevate-on-scroll ref="appBar">
         <v-app-bar-nav-icon
            :style="{color: parameters.primaryColor}"
            @click="parameters.openLeftMenu = !parameters.openLeftMenu"
        ></v-app-bar-nav-icon>
        <a @click="$router.push('/')">
            <img
                :src="logo"
                :alt="parameters.store.cf1nome"
                class="ml-12"
                height="60px"
            />
        </a>
        <v-spacer></v-spacer>

        <v-text-field
            v-model="product.search"
            placeholder="Ex.: 123456/camisa branca..."
            dense
            flat
            hide-details
            rounded
            solo-inverted
            class="pr-2"
            @keydown="search"
        ></v-text-field>
        <v-menu offset-y :nudge-width="130">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="text-capitalize px-2"
                    text
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    :color="parameters.primaryColor"
                >
                    <v-icon>mdi-account</v-icon>
                    Minha conta
                </v-btn>
            </template>
            <AppBarCardMyAccount />
        </v-menu>

        <AppCartBtn />
        <slot name="extension" slot="extension">
            <AppBarMenu v-if="show()"/>
        </slot>
    </v-app-bar>
</template>

<script>
const AppBarMenu = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/header/AppBarMenu.vue"
    );
const AppBarCardMyAccount = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/header/AppBarCardMyAccount.vue"
    );
const AppCartBtn = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/header/AppCartBtn.vue"
    );

export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        logo(){
            return `../../../../../img/${process.env.VUE_APP_ENV_NAME}/logo.png`
        }
    },
    components: {
        AppBarMenu,
        AppBarCardMyAccount,
        AppCartBtn
    },
    methods:{
        search(event){
            if (event.keyCode === 13 &&
                !event.shiftKey &&
                this.product.search)
            {
                this.$store.dispatch("product/clearFilteredFields")
                this.$router.push({path: "/pesquisa", query:{ search: this.product.search }})
                event.preventDefault();
            }
        },
        show(){
            if(Object.keys(this.$refs).length === 0 && this.$refs.constructor === Object)
                return true
            return this.$refs.appBar.hideShadow
        }
    }
};
</script>

<style scoped>
.v-app-bar >>> .v-toolbar__extension {
    padding: 0px !important
}
</style>