import { Utils } from "@/plugins/utils";
import axios from 'axios'
import router from '@/router';

export default {
    namespaced: true,
    state: {
        loading: false,
        user: {}
    },
    getters: {
      
    },
    mutations: {
        clear(state){
            state.user = {}
            state.loading = false
        }
    },
    actions: {
        async recover(store){
            store.state.loading = true
            let payload = await axios.put(process.env.VUE_APP_API_URI + "/Authentication/Authentication/RecoverPass", store.state.user , { 'Content-Type': 'application/json' })
            .then(response => {
                let isRecover = Utils.ValidateAxiosResponse(response.data);
                store.state.loading = false
                store.commit("clear")
                if (isRecover) {
                    store.dispatch("parameters/showSnackBar", {
                        text: "Senha recuperada com sucesso!",
                        show: true,
                        timeout: 4000
                    }, {root: true})
                    router.push("/login")
                    return true
                }
                throw new Error("Não foi possível recuperar a sua senha.")
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
                return false
            });
            store.state.loading = false
            return payload
        },
    }
}