import { Utils } from "@/plugins/utils";

export default {
    namespaced: true,
    state: {
        lstCustomers: [],
        loading: false,
        customer: {},
        showDialog: false
    },
    getters: {
      
    },
    mutations: {
        setCustomers(state, lstCustomers){
            state.lstCustomers = lstCustomers
        },
        clear(state){
            state.showDialog = false
            state.customer = {}
            state.lstCustomers = []
            state.loading = false
        }
    },
    actions: {
        async find(store){
            store.state.loading = true
            await this._vm.$axios.get("/Customer/Customer/FindPending")
            .then(response => {
                let lstCustomers = Utils.ValidateAxiosResponse(response.data);
                store.commit("setCustomers", lstCustomers)
                return true
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.state.loading = false
        },
        async confirm(store){
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: true, text: "Confirmando cadastro...", indeterminate: true }, {root: true})
            await this._vm.$axios.put("/Customer/Customer/Confirm" , { cf1cliente: store.state.customer.cf1cliente })
            .then(response => {
                let success = Utils.ValidateAxiosResponse(response.data);
                if (success) {
                    store.commit("clear")
                    store.dispatch("find")
                    return true
                }
                throw new Error("Não foi possível confirmar o cadastro do cliente")
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.dispatch("parameters/openGlobalLoading", { showLoadingGlobal: false, text: "" }, {root: true})
        }
    }
}