<template>
    <v-footer dark padless>
        <v-card
            flat
            tile
            class="white--text text-center"
            :color="parameters.primaryColor"
            width="100%"
        >
            <v-card-text>
                <template v-for="socialMedia in parameters.store.socialMedias">
                    <v-btn
                        v-if="socialMedia.b2mlink"
                        :key="socialMedia.icon"
                        class="mx-4 white--text"
                        icon
                        @click="openSocialMedia(socialMedia.b2mlink)"
                    >
                        <v-icon size="24px">
                            {{ socialMedia.icon }}
                        </v-icon>
                    </v-btn>
                </template>
            </v-card-text>

            <AppFooterBodyMobile v-if="$mq != 'lg'" />
            <AppFooterBodyDesktop v-else />

            <v-divider></v-divider>

            <v-card-text class="white--text">
                <strong>{{ parameters.store.cf1nome }} - CNPJ:
                    {{ parameters.store.lx0cliente | cpfCnpj }} —
                    {{ new Date().getFullYear() }}</strong>
            </v-card-text>
            <v-card-text
                class="text-right"
                style="backgroundColor: white !important"
                :style="{color: parameters.primaryColor}"
            >
                Desenvolvido por
                <a href="https://magnadata.com.br/">Magnadata Sistemas</a>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
const AppFooterBodyDesktop = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterBodyDesktop.vue"
    );
const AppFooterBodyMobile = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/footer/AppFooterBodyMobile.vue"
    );

export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods: {
        openSocialMedia(link) {
            window.open(link);
        },
    },
    components:{
        AppFooterBodyDesktop,
        AppFooterBodyMobile
    }
};
</script>

<style>
</style>