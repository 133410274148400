<template>
    <v-main style="min-height: 80% !important;" class="pb-6">
        <AppMainCarousel />
        <!-- <AppMainBestSellingItems /> -->
        <AppMainProducts />
    </v-main>
</template>

<script>
const AppMainCarousel = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/body/AppMainCarousel.vue"
    );
const AppMainProducts = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/home/body/AppMainProducts.vue"
    );
export default {
    components: {
        AppMainCarousel,
        AppMainProducts,
    },
};
</script>

<style>
</style>