import Vue from 'vue';

/* Vue.filter('userName', value => {
    return value.split("@")[0];
}); */

Vue.filter("shortDate", value => {
    if (value || value != null)
        return dateWithoutTimezone(value, 'numeric')
    return ""
})
Vue.filter('completeDate', value => {
    if(value.length == 4 ) return value;
    return dateWithoutTimezone(value, "numeric");
});

const dateWithoutTimezone = (value, yearDigit) => {
    let date = new Date(value);
    var offset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() + offset);
    return date.toLocaleDateString(navigator.language, { year: yearDigit, month: '2-digit', day: '2-digit' })
}

Vue.filter('cpfCnpj', value => {
    if (!value)
        return value

    value = value.replace(/[^\d]/g, "");
    if (value.length == 11) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");    
    }
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5");
    
});

Vue.filter("cnpj", value => {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5");
})

Vue.filter('money', value => {
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
    }).format(value);
});

Vue.filter('capitalize', value => {
    if(!value)
        return value
    value = (value+"").toLowerCase();
    return value.replace(/(?:^|\s|[-"'([{])+\S/g, (c) => c.toUpperCase())
});

Vue.filter("cep", value => {
    if(!value)
        return value
    return value.replace(/(\d{2})(\d{3})(\d{3})/g, "$1.$2-$3");
})

Vue.filter("phone", value => {
    value=value.replace("+55","");
    value=value.replace(/\D/g,"");             //Remove tudo o que não é dígito
    value=value.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    value=value.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
    return value;
})

Vue.filter('percent', value => {
	if (!value) {
		"0,00%"
	}
	value = value+"".replace(",", "")
	value = value.replace(".", ",")
	return `${value}%`
})